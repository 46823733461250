import { useEffect, useState } from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer
} from "recharts";
import { useDispatch } from "react-redux";
import axios from "../../axios";
import { formatMoney, formatNumber } from '../../utils';
import dayjs from "dayjs";
import Loading from "../common/Loading";
import EmptyState from "../common/EmptyState";
import toastService from "../../services/toastService";

const styles = {
  select: {
    width: "110px",
    padding: "4px 8px",
    "&::after": {
      display: "none"
    },
    "&::before": {
      display: "none"
    },
    "&.MuiFormControl-root": {
      mb: 0
    },
    "& .MuiInputBase-input.MuiSelect-select": {
      minHeight: "unset",
      padding: 0,
      backgroundColor: "transparent"
    },
    "& .MuiOutlinedInput-input": {
      p: "0 32px 0 8px",
      "@media screen and (max-width: 1024px)": {
        fontSize: 14
      }
    },
    "& .MuiOutlinedInput-root.MuiInputBase-root": {
      border: "none",
      background: "transparent"
    },
    "&.MuiInputBase-root": {
      border: "1px solid #EEF0F2",
      borderRadius: "8px",
      // borderTopRightRadius: "0px",
      // borderBottomRightRadius: "0px",
      backgroundColor: "transparent"
    },
    "& .MuiSelect-root, &.MuiInputBase-root.Mui-focused .MuiSelect-root": {
      backgroundColor: "transparent"
    },
    "& .MuiSelect-icon": {
      color: "colors.statusNeutralDark"
    },
    "& .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused": {
      border: "none",
      background: "transparent"
    }
  }
};

const TotalRevenueChart = ({ selectedCompany }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [displayBy, setDisplayBy] = useState(48);
  const [chardData, setChardData] = useState([]);
  const [yDomain, setYDomain] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios
      .get(
        `/overview/charts?filter=${displayBy}&${selectedCompany
          .filter((el) => el !== 0)
          .map((el) => `company_id[]=${el}`)
          .join("&")}`
      )
      .then((response) => {
        let dataArr = [];

        for (const [key, value] of Object.entries(response.data?.charts)) {
          dataArr.push({
            name: key,
            value: value.total_charges,
            stops: value.total_stops,
            date: key
          });
        }

        const valueArr = dataArr.map((el) => el.value);
        const minAndMax = [Math.min(...valueArr), Math.max(...valueArr)];

        setYDomain([
          Math.ceil((minAndMax[0] - 49000) / 50000) * 20000,
          Math.round((minAndMax[1] + 20000) / 20000) * 20000
        ]);

        setChardData(
          dataArr
            .sort((a, b) => new Date(a.name) - new Date(b.name))
            .reduce((acc, el) => [...acc, { ...el, name: dayjs(el.name).format("MMM DD") }], [])
        );
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || err.message)
      )
      .finally(() => setLoading(false));
  }, [displayBy, selectedCompany]);

  const handleRangeChange = (e) => setDisplayBy(e.target.value);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-area">
          <p className="label">{dayjs(payload[0].payload.date).format("MMM DD, YYYY")}</p>
          <p className="value">{formatMoney(payload[0].payload.value)}</p>
          <p className="stops">{formatNumber(payload[0].payload.stops)}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="overview-revenue">
      <div className="chart-header">
        <h4>Total Revenue</h4>
        <div className="display-by-select-wrapper">
          <div className="display-by-select">
            <Typography>Display by:</Typography>

            <Select
              name="displayBy"
              value={displayBy}
              onChange={handleRangeChange}
              variant="standard"
              sx={styles.select}
              IconComponent={KeyboardArrowDown}>
              <MenuItem value={2}>2 Weeks</MenuItem>
              <MenuItem value={4}>Month</MenuItem>
              <MenuItem value={12}>Quarter</MenuItem>
              <MenuItem value={24}>6 Month</MenuItem>
              <MenuItem value={48}>Year</MenuItem>
              <MenuItem value={9999}>All time</MenuItem>
            </Select>
          </div>
          {/* <div className="display-by-select-date">
            <CalendarMonth fontSize="small" sx={{ mr: 0.5 }} />
            {chardData.length
              ? `${
                  displayBy === 48
                    ? dayjs(chardData[chardData.length - 1].name)
                        .subtract(1, "year")
                        .format("MMM D")
                    : dayjs(chardData[0].name).format("MMM D")
                } - ${dayjs(chardData[chardData.length - 1].name).format("MMM D")}`
              : `${dayjs().subtract(displayBy, "week").format("MMM D")} - ${dayjs().format(
                  "MMM D"
                )}`}
          </div> */}
        </div>
      </div>

      {chardData.length < 1 && !loading ? (
        <EmptyState title="No Chart Data" />
      ) : (
        <div className="revenue-average-result">
          {loading ? (
            <Loading />
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={chardData}
                margin={{
                  top: 20,
                  right: 10,
                  left: 0,
                  bottom: 0
                }}>
                <CartesianGrid strokeDasharray="0" vertical={false} />
                <XAxis dataKey="name" tickMargin={15} />
                <YAxis
                  tickMargin={10}
                  type="number"
                  tickFormatter={(value) => {
                    const valueT =
                      Math.abs(value) > 999
                        ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + "k"
                        : Math.sign(value) * Math.abs(value);

                    return valueT;
                  }}
                  domain={yDomain}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#4253F0"
                  fill="rgba(66, 83, 240, 0.2)"
                  strokeWidth={2}
                  activeDot={{ strokeWidth: 2, r: 6 }}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default TotalRevenueChart;
